import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import Posts from '../components/Posts'
import SEO from '../components/SEO'

const PostsPage = ({ data }) => {
  const {
    allMdx: { nodes: posts },
  } = data
  return (
    <>
      <Layout>
        <SEO title="記事一覧" />
        {/* <Posts posts={posts} title="記事一覧" /> */}
        <Posts posts={posts} />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        excerpt(pruneLength: 100, truncate: true)
        frontmatter {
          title
          author
          category
          readTime
          date(formatString: "YYYY年MM月DD日")
          slug
          image {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        id
      }
    }
  }
`

export default PostsPage
