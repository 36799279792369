import React from 'react'
import Post from './Post'
import Banner from '../Banner'
import styled from 'styled-components'
import Pagination from '../Pagination'

const Posts = ({ posts, title, pageContext, hasPagination }) => {
  return (
    <section className="posts">
      {title && <CategoryHeader>{title}</CategoryHeader>}
      <div className="posts-center">
        {/* posts column */}
        <article>
          {posts.map(post => {
            return <Post key={post.id} {...post} />
          })}
          {hasPagination && <Pagination pageContext={pageContext}></Pagination>}
        </article>

        {/* banner column */}
        <article>
          <Banner />
        </article>
      </div>
    </section>
  )
}

export default Posts

const CategoryHeader = styled.h3`
  /* font-weight: 700;
  text-transform: uppercase;
  color: #e12d39;
  font-size: 1.25rem; */
  margin: auto;
  /* margin-top: 1rem; */

  margin-bottom: 3.5rem;
  font-family: -webkit-body;
  text-align: center;

  text-transform: uppercase;
  /* font-size: 26px; */
  letter-spacing: 1px;

  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 16px 0;
  grid-gap: 22px;
  width: 50%;

  &:after,
  &:before {
    content: ' ';
    display: block;
    border-bottom: 2px solid var(--clr-primary-5);
  }
`
