import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Pagination = ({ pageContext }) => {
  // let currentPage=4
  // let numPages=5

  // if (pageContext) {
  //   const { currentPage, numPages } = pageContext
  // }
  const { currentPage, numPages } = pageContext

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  let pageOption = []

  if (currentPage <= 2) {
    pageOption = [1, 2, 3]
  } else if (currentPage + 1 >= numPages) {
    pageOption = [numPages - 2, numPages - 1, numPages]
  } else {
    pageOption = [currentPage - 1, currentPage, currentPage + 1]
  }

  return (
    <PaginationDiv>
      <Nums>
        {isFirst ? (
          <Link to={`/blog/${prevPage}`} rel="prev" className="pageLink hidden">
            &laquo;
          </Link>
        ) : (
          <Link to={`/blog/${prevPage}`} rel="prev" className="pageLink">
            &laquo;
          </Link>
        )}
        {0 <= currentPage - 3 ? (
          <>
            <Link to={`/blog/`} className="pageLink" activeClassName="active">
              {1}
            </Link>
            {0 <= currentPage - 4 ? <span className="dots">...</span> : <></>}
          </>
        ) : (
          <></>
        )}
        {pageOption.map(i => {
          const linkPage = i == 1 ? `` : i
          return (
            <Link
              to={`/blog/${linkPage}`}
              className="pageLink"
              activeClassName="active"
            >
              {i}
            </Link>
          )
        })}

        {/* 恣意的だが５くらいが最終点を表示する目安か。 */}
        {currentPage + 2 <= numPages ? (
          <>
            {currentPage + 3 <= numPages ? (
              <span className="dots">...</span>
            ) : (
              <></>
            )}
            <Link
              to={`/blog/${numPages}`}
              className="pageLink"
              activeClassName="active"
            >
              {numPages}
            </Link>
          </>
        ) : (
          <></>
        )}
        {isLast ? (
          <Link to={`/blog/${nextPage}`} rel="next" className="pageLink hidden">
            &raquo;
          </Link>
        ) : (
          <Link to={`/blog/${nextPage}`} rel="next" className="pageLink">
            &raquo;
          </Link>
        )}
      </Nums>
    </PaginationDiv>
  )
}

Pagination.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

const PaginationDiv = styled.div`
  text-align: center;
`

const Nums = styled.div`
  display: inline-block;
  margin-bottom: 2rem;
  .hidden {
    visibility: hidden;
  }

  .dots {
    color: var(--clr-grey-1);
    float: left;
    padding: 6px 12px;
    text-decoration: none;
  }
  .pageLink {
    color: var(--clr-grey-1);
    float: left;
    padding: 6px 12px;
    text-decoration: none;

    &:hover:not(.active .dots) {
      background-color: #ddd;
      border-radius: 5px;
    }
  }
  .active {
    background-color: var(--clr-green-dark);
    color: white;
    border-radius: 5px;
  }
  @media (min-width: 576px) {
    .pageLink {
      padding: 8px 16px;
    }
    .dots {
      padding: 8px 16px;
    }
    .active {
      border-radius: 4px;
    }
  }
`

export default Pagination
